import { FC } from 'react';
import { Link } from 'routes';

import { logEvent } from 'utils/analytics';
import { trackingEventForGA4 } from 'utils/eventTrackingForGA4';

import styles from './ProAward2023BannerPromotion.module.scss';

const ProAward2023BannerPromotion: FC = () => {
  const handleLinkClick = () => {
    const gaEvent = {
      category: 'ProAward2023',
      action: 'topPageProAward2023BannerClicked',
      label: 'top',
    };
    // NOTE: Delete logEvent when migrate to GA4
    logEvent(gaEvent.category, gaEvent.action, gaEvent.label);
    trackingEventForGA4(gaEvent.action, gaEvent.category, gaEvent.label);
  };

  const bannerTextImageUrl = '/static/images/pro-award-2023/top-page-banner-text.svg';

  return (
    <div className={styles['banner']}>
      <Link prefetch={false} route="/pro-award-2023">
        <a role="link" onClick={handleLinkClick} tabIndex={0}>
          <div className={styles['text-wrapper']}>
            <img
              alt="PRO AWARD 2023"
              className={styles['banner-text']}
              data-test-id="pro-award-2023-title"
              src={bannerTextImageUrl}
            />
          </div>
        </a>
      </Link>
    </div>
  );
};

export default ProAward2023BannerPromotion;
