import { FC } from 'react';
import classNames from 'classnames';

import styles from './ArrowInCircle.module.scss';

interface Props {
  invert?: boolean;
}

export const ArrowInCircle: FC<Props> = ({ invert = false }) => (
  <span
    className={classNames(styles.arrow, { [styles['inverted']]: invert })}
  ></span>
);

export type { Props as ArrowInCircleProps };
export default ArrowInCircle;
