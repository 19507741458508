import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import classNames from 'classnames';

import CategoryPreviewSubCategoryList from 'components/molecules/CategoryPreviewSubCategoryList';
import JournalGroup from 'components/molecules/JournalGroup';
import CategoryLinks from 'components/organisms/CategoryLinks';
import { linksData } from 'components/organisms/CategoryLinks/linksData';
import ProCTALink from 'components/atoms/ProCTALink';
import ServiceOfTheMonth from 'components/organisms/ServiceOfTheMonth';
import SectionFeatures from 'modules/marketing-pages/components/SectionFeatures';
import HomeBanner from 'modules/static-pages/components/HomeBanner';
import ProSignUpPromotionBanner from 'modules/static-pages/components/ProSignUpPromotionBanner';
import ProAward2023BannerPromotion from 'modules/static-pages/components/ProAward2023/ProAward2023BannerPromotion';

import RequestFormCTA from 'components/molecules/RequestFormCTA';
import { loadDataLayerVariables } from 'utils/eventTrackingForGA4';

import styles from './HomeContent.module.scss';

import { openNewRequestModal } from 'modules/requests/service';
import { useSocialRequestContinuation } from 'src/hooks';

interface Props {
  categoryTree: Array<{
    category: {
      _id: string;
      categoryLevel?: 'c1' | 'c2' | 'c3' | 'vert';
      children: Record<string, { children?: Record<string, any>; id: string; title: string }>;
      id: string;
      pageBanner: string;
      title: string;
    };
    posts: Array<any>;
  }>;
}

const HomeContent: FC<Props> = ({ categoryTree }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const bannerRef = useRef<HTMLDivElement>(null);
  const [isBannerInViewport, setIsBannerInViewport] = useState(false);
  const focusOnJobTypeSearchInput = useCallback(() => {
    const input = document.querySelector<HTMLInputElement>('#homeBannerJobTypeSearch');

    if (bannerRef.current && input) {
      const DEBOUNCE_TIME = 300;

      const tempScroll = fromEvent(window, 'scroll')
        .pipe(debounceTime(DEBOUNCE_TIME))
        .subscribe(() => {
          input.focus();
          tempScroll.unsubscribe();
        });
      bannerRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const elementVisibility = entries.map((entry) => entry.intersectionRatio > 0);
      if (entries.length) {
        setIsBannerInViewport(!elementVisibility.some(Boolean));
      }
    });
    if (bannerRef.current) observer.observe(bannerRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  const { hasVisitedSocialField, vertical } = useSocialRequestContinuation();

  useEffect(() => {
    if (hasVisitedSocialField) {
      loadDataLayerVariables();
      openNewRequestModal({
        dispatch,
        properties: {
          jobTypeId: vertical,
        },
      });
    }
  }, [dispatch, vertical, hasVisitedSocialField]);

  return (
    <>
      <ProSignUpPromotionBanner eventType={'topPageProCTAClicked'} eventLabel={'top'} />
      <HomeBanner ref={bannerRef}>
        <h1 className={styles['banner-title']}>{t('common:topPage.bannerTitle')}</h1>
        <p className={styles['banner-subtitle']}>{t('common:topPage.bannerSubtitle')}</p>
      </HomeBanner>
      <div className={styles.background} data-cy={'home-content'}>
        <div className={styles.white}>
          <div className={classNames(styles.container, styles['service-of-the-month-container'])}>
            <ServiceOfTheMonth />
          </div>
          <div className={styles.container}>
            <h2 className={styles['category-section-title']}>
              {t('common:topPage.findFromCategories')}
            </h2>
            {categoryTree.map(({ category, posts }) => {
              const c1id = category._id as
                | 'home'
                | 'learning'
                | 'events'
                | 'lifestyle'
                | 'business';
              return (
                <div key={category._id} className={styles['category-section']}>
                  <CategoryPreviewSubCategoryList
                    category={category}
                    isHome={true}
                    verticals={[]}
                    subCategories={Object.values(category.children)}
                    topCategoryId={category.id}
                  />
                  <CategoryLinks links={linksData[c1id]} />
                  {posts.length > 0 && (
                    <div className={styles['journal-section']} data-cy={'blog'}>
                      <h4>{t('common:topPage.journalSection', { category: category.title })}</h4>
                      <JournalGroup posts={posts} />
                    </div>
                  )}
                </div>
              );
            })}
          </div>
          <div className={styles.container}>
            <ProCTALink eventType={'topPageProCTAClicked'} dataGtmClick="gtm-proCTA" />
          </div>
          <div className={styles.container}>
            <ProAward2023BannerPromotion /> <br />
          </div>
        </div>
        <div className={styles.container}>
          <SectionFeatures translationId="homeHowItWork" />
        </div>
        <div className={styles.container}>
          <SectionFeatures translationId="whyUseZehitomoWidget" />
        </div>
        <div
          className={classNames(styles['cta-container'], {
            [styles['cta-sticky']]: isBannerInViewport,
          })}
        >
          <RequestFormCTA hasDropShadow={true} onClick={focusOnJobTypeSearchInput} />
        </div>
      </div>
    </>
  );
};

export default HomeContent;
