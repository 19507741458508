import { FC, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Image from 'next/image';
import _ from 'lodash';
import classNames from 'classnames';
import { addTransformToCloudinaryUrl } from 'utils/url';

import { ServiceOfTheMonthJobType } from 'modules/job-types/types';
import { Link } from 'routes';
import { RootState } from 'store/types';

import CarouselIcon from '../../atoms/CarouselIcon';

import styles from './ServiceOfTheMonth.module.scss';

const TOTAL_COUNT = 12;
const DISPLAY_PER_PAGE = 6;

const TRANSLATIONS = {
  en: {
    description: 'Choose from our current popular services.',
    serviceOfMonth: 'Recommended services of the month',
  },
  ja: {
    description: '今の季節に人気のサービスをピックアップ',
    serviceOfMonth: '今月のおすすめサービス',
  },
} as Record<string, { description: string; serviceOfMonth: string }>;

export const ServiceOfTheMonth: FC = () => {
  const verticals = useSelector((state: RootState) => state.jobTypes.serviceOfTheMonth);
  const [popularVerticals, setPopularVerticalsToDisplay] = useState<
    Array<ServiceOfTheMonthJobType>
  >(verticals.slice(0, TOTAL_COUNT));
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const {
    i18n: { language },
  } = useTranslation();

  useEffect(
    () => {
      setPopularVerticalsToDisplay(_.sampleSize(verticals, TOTAL_COUNT));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(verticals)]
  );

  const canScrollRight = popularVerticals.length > DISPLAY_PER_PAGE * (currentPageIndex + 1);
  const urlPrefix = language === 'ja' ? '/' : `/${language}/`;

  return (
    <section className={styles.section}>
      <h2 className={styles.heading} data-cy={'service-of-month'}>
        {TRANSLATIONS[language].serviceOfMonth}
      </h2>
      <p className={styles.description}>{TRANSLATIONS[language].description}</p>
      <div className={styles.container} data-cy={'service-of-month-next-page'}>
        {currentPageIndex > 0 && (
          <div className={styles['arrow-left']}>
            <CarouselIcon
              direction="left"
              // eslint-disable-next-line react/jsx-no-bind
              onClick={() => setCurrentPageIndex(currentPageIndex - 1)}
            />
          </div>
        )}
        <div className={styles['photo-grid']} data-cy={'slider'}>
          {popularVerticals.map((vertical, index) => (
            <div
              className={classNames({
                [styles.hidden]:
                  index < currentPageIndex * DISPLAY_PER_PAGE ||
                  index >= (currentPageIndex + 1) * DISPLAY_PER_PAGE,
              })}
              key={vertical._id}
            >
              <Link
                prefetch={false}
                route={`${urlPrefix}${vertical.categories.join('/')}/${vertical._id}`}
              >
                <a data-test-id="popular-vertical">
                  <div className={styles['image-container']}>
                    {/* NOTE: The width and height are using the values defined in CSS directly for PC */}
                    <Image
                      alt={vertical.translations.requestType}
                      src={addTransformToCloudinaryUrl(vertical.imageUrl, {
                        dpr: 'auto',
                        width: '273',
                      })}
                      width={273}
                      height={150}
                    />
                  </div>
                  <p className={styles['service-text']}>{vertical.translations.requestType}</p>
                </a>
              </Link>
            </div>
          ))}
          {canScrollRight && (
            <div className={styles['arrow-right']}>
              <CarouselIcon
                direction="right"
                // eslint-disable-next-line react/jsx-no-bind
                onClick={() => setCurrentPageIndex(currentPageIndex + 1)}
              />
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default ServiceOfTheMonth;
