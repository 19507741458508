import { FC } from 'react';
import { Store } from 'redux';
import { LogicMiddleware } from 'redux-logic';
import * as Sentry from '@sentry/nextjs';
import { useTranslation } from 'react-i18next';

import Layout from 'modules/layout';
import { HomeContent, homeData } from 'modules/static-pages/pages/home';
import { getAllC1CategoryTree } from 'modules/job-categories/duck';
import { listServiceOfTheMonth } from 'modules/job-types/duck';
import {
  getInternalBlogPostsByCategoryId,
  listBlogPostsByCategoryId,
} from 'modules/marketing-pages/service';
import { RootState } from 'store/types';
import { setCachedStates } from 'store/actions';
import { wrapper } from 'src/store';
import { setupPageAndLoadTranslations } from 'utils/page';
import { isDefined } from 'utils/type-guards';
import FeatureFlagsService from 'src/services/feature-flags';
import { getSSRCache, setSSRCache } from 'src/ssr-cache';
import { generateUrlWithoutUnwantedParameters } from 'src/utils/url';

const featureFlagsService = FeatureFlagsService.getInstance();

interface CategoryTree {
  _id: string;
  categoryLevel?: 'c1' | 'c2' | 'c3' | 'vert';
  children: Record<string, { children?: Record<string, any>; id: string; title: string }>;
  id: string;
  pageBanner: string;
  title: string;
}

interface Props {
  categoryTreeWithBlogPosts: Array<{
    category: CategoryTree;
    posts: Array<any>;
  }>;
  url: string;
}

const IndexPage: FC<Props> = ({ categoryTreeWithBlogPosts, url }) => {
  const {
    i18n: { language = 'ja' },
  } = useTranslation();

  return (
    // @ts-expect-error Language is hard coded to two values
    <Layout {...homeData[language]} language={language} url={url}>
      <HomeContent categoryTree={categoryTreeWithBlogPosts} />
    </Layout>
  );
};

export const getServerSideProps = wrapper.getServerSideProps(
  (store) =>
    async ({ req, defaultLocale, locale, query, resolvedUrl }) => {
      const { dispatch, getState, logicMiddleware } = store as Store<RootState> & {
        logicMiddleware: LogicMiddleware;
      };

      if (resolvedUrl) {
        const reductedResolvedUrl = generateUrlWithoutUnwantedParameters(resolvedUrl);
        if (reductedResolvedUrl !== resolvedUrl) {
          // The permanent redirection here is done to prevent google search console from flagging page as duplicate
          return {
            redirect: {
              destination: reductedResolvedUrl,
              permanent: true,
            },
          };
        }
      }

      const cacheKeyProps = {
        locale,
        pageName: 'top-page',
        query,
        url: req.url ?? '',
      };
      const cachedProps = await getSSRCache(cacheKeyProps, 'props');
      const cachedStates = await getSSRCache(cacheKeyProps, 'states');

      if (cachedProps && cachedStates) {
        dispatch(setCachedStates(cachedStates as RootState));
        await logicMiddleware.whenComplete();
        return { props: cachedProps };
      }

      const { i18nData } = await setupPageAndLoadTranslations({
        defaultLocale,
        locale,
        namespaces: ['common', 'form', 'marketingPages', 'request'],
        remoteTranslations: ['homeHowItWork', 'whyUseZehitomoWidget'],
        // @ts-expect-error Extended store
        store,
      });
      dispatch(getAllC1CategoryTree());
      dispatch(listServiceOfTheMonth());
      await logicMiddleware.whenComplete();
      const state = getState();

      const categoryTrees = state.jobCategories.c1CategoryTree as Array<CategoryTree>;

      let categoryTreeWithBlogPosts: Props['categoryTreeWithBlogPosts'] = [];
      // TODO(micah): Remove toggle after successful release. Add exclusion for HOME if it is still excluded.
      const enabledInternalBlogPostsForC1 =
        await featureFlagsService.getEnabledInternalBlogPostsByCategoryOne();
      const blogPosts = await Promise.allSettled(
        categoryTrees.map(({ _id }) => {
          if (enabledInternalBlogPostsForC1.has(_id)) {
            return getInternalBlogPostsByCategoryId({ categoryId: _id });
          }
          return listBlogPostsByCategoryId(_id);
        })
      );
      categoryTreeWithBlogPosts = categoryTrees
        .map((categoryTree, index) => {
          const promiseResult = blogPosts[index];
          if (promiseResult.status === 'fulfilled') {
            return {
              category: categoryTree,
              posts: promiseResult.value,
            };
          }

          Sentry.captureException(promiseResult.reason);
          return undefined;
        })
        .filter(isDefined);

      const serverSideProps = {
        ...i18nData,
        categoryTreeWithBlogPosts,
      };

      setSSRCache(cacheKeyProps, serverSideProps, 'props');
      setSSRCache(cacheKeyProps, state, 'states');

      return {
        props: serverSideProps,
      };
    }
);

export default IndexPage;
