import React, { forwardRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Jumbotron from 'modules/common/components/Jumbotron';
import JobTypeSearch from 'modules/job-types/components/JobTypeSearch';
import { Link } from 'routes';

import styles from './HomeBanner.module.scss';
import Image from 'next/image';

import { logEvent } from 'utils/analytics';
import { trackingEventForGA4 } from 'utils/eventTrackingForGA4';
import { RootState } from 'store/types';

const HomeBannerWithRef = forwardRef<HTMLDivElement, { children: React.ReactNode }>(
  ({ children }, ref) => {
    const {
      i18n: { language },
      t,
    } = useTranslation();
    const recommendedVerticals = useSelector(
      (state: RootState) => state.jobTypes.serviceOfTheMonth
    );
    const popular = recommendedVerticals.map((vertical) => ({
      id: vertical._id,
      title: vertical.translations.requestType,
    }));

    const handleLinkClick = useCallback(() => {
      // NOTE: Delete logEvent when migrate to GA4
      logEvent('proSignUp', 'topPageProCTAClicked', 'ATF');
      trackingEventForGA4('topPageProCTAClicked', 'proSignUp', 'ATF');
    }, []);

    return (
      <Jumbotron bgImg="/static/images/home/hero-bg.jpg" bgImgPosition="absolute">
        <div className={styles.container} ref={ref}>
          <div className={styles.banner}>
            <div className={styles.phone}>
              <Image
                alt={t('header.homeBanner')}
                src={`/static/images/home/phone.${language}.png`}
                height={365}
                width={365}
              />
            </div>
            <div className={styles.content}>{children}</div>
            <div className={styles['job-type-search-container']}>
              <JobTypeSearch id="homeBannerJobTypeSearch" popular={popular} />
            </div>
          </div>
          <Link prefetch={false} route="/pro">
            <a
              className={styles['pro-page-link']}
              role="link"
              onClick={handleLinkClick}
              data-gtm-click="gtm-proRegistButton-topTextLink"
              tabIndex={0}
            >
              {t('joinAsAPro')}
            </a>
          </Link>
        </div>
      </Jumbotron>
    );
  }
);
HomeBannerWithRef.displayName = 'HomeBannerWithRef';

export default HomeBannerWithRef;
