export const linksData = {
  learning: {
    title: '習い事関連の人気のサービス ',
    c2Categories: [
      {
        title: 'トレーニング',
        links: [
          {
            jobType: 'パーソナルトレーニング',
            url: '/learning/training/personal-training/personal-trainer',
          },
          {
            jobType: 'ヨガレッスン',
            url: '/learning/training/yoga/yoga-instructor',
          },
          {
            jobType: '水泳レッスン',
            url: '/learning/training/swimming/swimming-lessons',
          },
          {
            jobType: 'ストレッチレッスン',
            url: '/learning/training/stretching/stretching-instructor',
          },
          {
            jobType: 'ピラティスレッスン',
            url: '/learning/training/pilates/pilates-instructor',
          },
          {
            jobType: 'ランニングレッスン',
            url: '/learning/training/running/running-instructor',
          },
          {
            jobType: 'ズンバレッスン',
            url: '/learning/training/zumba/zumba-instructor',
          },
          {
            jobType: 'ウォーキングレッスン',
            url: '/learning/training/walking/walking-instructor',
          },
        ],
      },
      {
        title: 'ダンス',
        links: [
          {
            jobType: 'ヒップホップダンスレッスン',
            url: '/learning/dance/hiphop/hip-hop-dance-instructor',
          },
          {
            jobType: 'バレエレッスン',
            url: '/learning/dance/ballet/ballet-instructor',
          },
          {
            jobType: 'チアダンス・チアリーディングレッスン',
            url: '/learning/dance/cheerleading/cheerdance-instructor',
          },
          {
            jobType: 'フラダンスレッスン',
            url: '/learning/dance/hula/hula-instructor',
          },
          {
            jobType: '日本舞踊(日舞)レッスン',
            url: '/learning/dance/japanese-dance/japanesedance-instructor',
          },
          {
            jobType: 'ポールダンスレッスン',
            url: '/learning/dance/pole-dance/poledance-instructor',
          },
          {
            jobType: '社交ダンスレッスン',
            url: '/learning/dance/ballroom-dance/ballroomdance-instructor',
          },
          {
            jobType: 'ベリーダンスレッスン',
            url: '/learning/dance/belly-dance/bellydance-instructor',
          },
          {
            jobType: 'タップダンスレッスン',
            url: '/learning/dance/tap-dance/tapdance-instructor',
          },
          {
            jobType: 'ブレイクダンスレッスン',
            url: '/learning/dance/breakdance/breakdance-instructor',
          },
        ],
      },
      {
        title: '音楽',
        links: [
          {
            jobType: 'ピアノレッスン',
            url: '/learning/music/music-instrumental-strings/piano-teacher',
          },
          {
            jobType: 'ボーカルレッスン',
            url: '/learning/music/voice-training/vocal-instructor',
          },
          {
            jobType: 'ウクレレレッスン',
            url: '/learning/music/music-instrumental-strings/ukulele-instructor',
          },
          {
            jobType: 'ギターレッスン',
            url: '/learning/music/music-instrumental-strings/guitar-teacher',
          },
          {
            jobType: 'バイオリンレッスン',
            url: '/learning/music/music-instrumental-strings/violin-instructor',
          },
          {
            jobType: 'ドラムレッスン',
            url: '/learning/music/music-instrumental-percussion/drumming-lessons',
          },
          {
            jobType: 'カラオケレッスン',
            url: '/learning/music/voice-training/karaoke-singing-instructor',
          },
          {
            jobType: 'チェロレッスン',
            url: '/learning/music/music-instrumental-strings/cello-instructor',
          },
          {
            jobType: 'フルートレッスン',
            url: '/learning/music/music-instrumental-wind/flute-instructor',
          },
          {
            jobType: 'サックスレッスン',
            url: '/learning/music/music-instrumental-wind/saxophone-instructor',
          },
        ],
      },
      {
        title: 'アート・芸術',
        links: [
          {
            jobType: '書道教室',
            url: '/learning/art/calligraphy/japanese-calligraphy-instructor',
          },
          {
            jobType: '絵画・デッサンレッスン',
            url: '/learning/art/drawing/painting-sketching-instructor',
          },
          {
            jobType: '写真・カメラレッスン',
            url: '/learning/art/photography-art/photography-instructor',
          },
          {
            jobType: 'パソコン教室',
            url: '/learning/tech/pc/computer-instructor',
          },
        ],
      },
      {
        title: '語学',
        links: [
          {
            jobType: '韓国語・ハングルレッスン',
            url: '/learning/language/korean/korean-teacher',
          },

          {
            jobType: '英語指導・英会話レッスン',
            url: '/learning/language/english/english-teacher',
          },
          {
            jobType: '手話レッスン',
            url: '/learning/language/sign-language/sign-teacher',
          },
        ],
      },

      {
        title: '生活・文化',
        links: [
          {
            jobType: 'フラワーアレンジメントレッスン',
            url: '/learning/life-and-culture/handicraft/flower-arrangement-instructor',
          },

          {
            jobType: '生け花教室',
            url: '/learning/life-and-culture/handicraft/ikebana-instructor',
          },
          {
            jobType: 'デザート・お菓子作りレッスン',
            url: '/learning/life-and-culture/baking-and-dessert/dessert-making-instructor',
          },
        ],
      },
      {
        title: 'スポーツ',
        links: [
          {
            jobType: 'ゴルフレッスン',
            url: '/learning/sports/ball-games/golf-instructor',
          },
          {
            jobType: 'テニスレッスン',
            url: '/learning/sports/ball-games/tennis-instructor',
          },
        ],
      },
    ],
  },
  lifestyle: {
    title: '暮らし関連の人気のサービス ',
    c2Categories: [
      {
        title: '',
        links: [
          {
            jobType: '便利屋',
            url: '/lifestyle/other-lifestyle/handymans/handyman',
          },

          {
            jobType: '家具組み立て（IKEA・ニトリなど）',
            url: '/lifestyle/other-lifestyle/assembly-repair/ikea-nitori-furniture-assembly',
          },
          {
            jobType: 'ネイリスト',
            url: '/lifestyle/health-and-beauty/nail/manicurist',
          },
          {
            jobType: 'ヘアメイク',
            url: '/lifestyle/health-and-beauty/hair/makeup-artist',
          },
          {
            jobType: '犬のしつけ教室・ドッグトレーニング',
            url: '/lifestyle/pets/pet-general/dog-therapist',
          },
          {
            jobType: 'ペットシッター・散歩代行',
            url: '/lifestyle/pets/pet-general/pet-sitter',
          },
          {
            jobType: '冷蔵庫修理',
            url: '/lifestyle/pc-electronics/fridge/refrigeratorrepair',
          },
        ],
      },
    ],
  },
  home: {
    title: '住宅関連の人気のサービス ',
    c2Categories: [
      {
        title: 'リフォーム',
        links: [
          {
            jobType: '駐車場・ガレージ',
            url: '/home/reform/parking-garage/garage-carport-professional',
          },

          {
            jobType: '外構・エクステリア工事',
            url: '/home/reform/exterior/home-exterior-construction',
          },
          {
            jobType: 'トイレリフォーム',
            url: '/home/reform/toilet/toilet-reform-specialist',
          },
          {
            jobType: 'お風呂・浴室リフォーム',
            url: '/home/reform/bathroom-renobation/bathroom-reform',
          },
          {
            jobType: '庭木の剪定',
            url: '/home/reform/yard-gardening/gardener',
          },
          {
            jobType: 'エアコンクリーニング',
            url: '/home/cleaning/electronics-cleaning/air-conditioner-cleaner',
          },
        ],
      },
      {
        title: '大規模工事',
        links: [
          {
            jobType: '大工・職人',
            url: '/home/large-scale-work/business-home/carpenter',
          },

          {
            jobType: '内装工事',
            url: '/home/large-scale-work/construction-large/interiorfinishingconstruction',
          },
        ],
      },
      {
        title: '修理・交換・取り付け',
        links: [
          {
            jobType: 'エアコン修理',
            url: '/home/repair-exchange-set/air-conditioner/air-conditioner-repair',
          },
          {
            jobType: 'エアコンの交換・取り付け',
            url: '/home/repair-exchange-set/indoor-installation/air-conditioner-installation',
          },
          {
            jobType: '網戸の張り替え・修理',
            url: '/home/repair-exchange-set/window-sash-repair/screen-door-replacement',
          },
          {
            jobType: '水漏れ修理・水道工事',
            url: '/home/repair-exchange-set/water-leak-water-trouble/plumbing-construction',
          },
          {
            jobType: '電気屋・電気工事',
            url: '/home/repair-exchange-set/electrical/electrical-construction',
          },
          {
            jobType: '合鍵・スペアキー',
            url: '/home/repair-exchange-set/crime-prevention/sparekeyproduction',
          },
          {
            jobType: '照明・シーリング・ダウンライトの交換・取り付け',
            url: '/home/repair-exchange-set/switch-plug-lighting/light-installation',
          },
          {
            jobType: '家具修理',
            url: '/home/repair-exchange-set/furniture/furniture-upholstery-repair',
          },
          {
            jobType: '障子張替え',
            url: '/home/repair-exchange-set/japanese-room-repair/shoji-door-replacement',
          },
          {
            jobType: '害虫駆除',
            url: '/home/repair-exchange-set/extermination/pest-control',
          },
          {
            jobType: '井戸ポンプ工事',
            url: '/home/repair-exchange-set/exterior-repair/wellconstruction',
          },
        ],
      },
    ],
  },
  business: {
    title: 'ビジネス関連の人気のサービス ',
    c2Categories: [
      {
        title: '',
        links: [
          {
            jobType: '司法書士',
            url: '/business/licenced-professional/legal/judicial-scrivener',
          },

          {
            jobType: '税理士',
            url: '/business/licenced-professional/finance-profession/tax-accountant',
          },
          {
            jobType: '行政書士',
            url: '/business/licenced-professional/finance-profession/administrative-scrivener',
          },
          {
            jobType: '不用品回収',
            url: '/business/cleaning/disposal/garbage-disposal',
          },
          {
            jobType: '産業廃棄物処理',
            url: '/business/cleaning/disposal/industrial-waste-treatment',
          },
          {
            jobType: 'ホームページ作成・制作',
            url: '/business/tech/web/homepage-creator',
          },
          {
            jobType: '画像編集・写真撮影',
            url: '/business/creative-content-production/photo/photo-editor',
          },
          {
            jobType: 'ビデオ撮影',
            url: '/business/creative-content-production/video/videographer',
          },
          {
            jobType: '動画撮影・編集',
            url: '/business/creative-content-production/video/movie-editor',
          },
          {
            jobType: 'グラフィックデザイン',
            url: '/business/art-and-design/design/graphic-designer',
          },
          {
            jobType: 'オフィス 清掃（クリーニング）',
            url: '/business/cleaning/building/office-cleaner',
          },
          {
            jobType: 'オフィスの移転・引っ越し',
            url: '/business/office-work/moving-office/mover-office',
          },
          {
            jobType: '消防用設備・用品・保守点検',
            url: '/business/security-and-safety/disaster-prevention/firefightingequipmentinspector',
          },
        ],
      },
    ],
  },
  events: {
    title: 'イベント関連の人気のサービス ',
    c2Categories: [
      {
        title: '',
        links: [
          {
            jobType: '出張シェフ',
            url: '/events/chefs/personal-chef',
          },

          {
            jobType: '写真撮影',
            url: '/events/photography/photographer',
          },
          {
            jobType: 'ケータリング',
            url: '/events/catering/catering',
          },
          {
            jobType: 'オーディション・宣材写真の写真撮影',
            url: '/events/photography/photographer-headshots',
          },
          {
            jobType: 'マタニティフォト・写真撮影',
            url: '/events/photography/photographer-maternity',
          },
          {
            jobType: '家族写真・記念写真の写真撮影',
            url: '/events/photography/photographer-family-portrait',
          },
          {
            jobType: '成人式の写真撮影',
            url: '/events/photography/photographer-coming-of-age',
          },
          {
            jobType: 'ベビーフォト・赤ちゃん写真撮影',
            url: '/events/photography/photographer-baby-photos',
          },
          {
            jobType: '七五三の写真撮影',
            url: '/events/photography/photographer-753-ceremony',
          },
          {
            jobType: '結婚式の写真撮影',
            url: '/events/photography/photographer-wedding',
          },
          {
            jobType: 'イベントの司会',
            url: '/events/mc/mc',
          },
          {
            jobType: '結婚式のウェディングプランナー',
            url: '/events/wedding-planner/wedding-planner',
          },
          {
            jobType: 'フラワーアレンジメント',
            url: '/events/event-florist/event-florist',
          },
          {
            jobType: '出前・宅配',
            url: '/events/food-delivery-service/fooddeliveryservice',
          },
          {
            jobType: 'イベントの段ボール製造',
            url: '/events/large-event/cardboard-production',
          },
        ],
      },
    ],
  },
};
