import { FC } from 'react';

import { VerticalLink } from 'components/organisms/CategoryLinks/types';
import { Link } from 'routes';

import styles from './LinksSection.module.scss';

interface Props {
  links: Array<VerticalLink>;
  title: string;
}

export const LinksSection: FC<Props> = ({ links, title }) => (
  <>
    <dt className={styles.title} data-cy={'popular-service-section'}>
      {title}
    </dt>
    <ul className={styles.list}>
      {links.map((link) => (
        <li className={styles.item} key={link.url}>
          <Link prefetch={false} route={link.url}>
            <a className={styles.link} data-cy={'popular-vertical'}>
              <dd>{link.jobType}</dd>
            </a>
          </Link>
        </li>
      ))}
    </ul>
  </>
);

export default LinksSection;
export type { Props as LinksSectionProps };
