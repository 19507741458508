import { useCallback, FC } from 'react';
import { Link } from 'routes';

import { logEvent } from 'utils/analytics';
import { trackingEventForGA4 } from 'utils/eventTrackingForGA4';
import ArrowInCircle from 'components/atoms/ArrowInCircle';

import styles from './ProSignUpPromotionBanner.module.scss';

interface Props {
  eventLabel?: 'C1-top' | 'C2-top' | 'C3-top' | 'Vert-top' | 'top' | '';
  eventType: 'verticalProCTAClicked' | 'topPageProCTAClicked';
}

const ProSignUpPromotionBanner: FC<Props> = ({ eventType, eventLabel = '' }: Props) => {
  const handleLinkClick = useCallback(() => {
    const gaEvent = {
      category: 'proSignUp',
      action: eventType,
      label: eventLabel,
    };
    // NOTE: Delete logEvent when migrate to GA4
    logEvent(gaEvent.category, gaEvent.action, gaEvent.label);
    trackingEventForGA4(gaEvent.action, gaEvent.category, gaEvent.label);
  }, [eventType, eventLabel]);

  return (
    <div className={styles['banner']}>
      <Link prefetch={false} route="/pro">
        <a
          role="link"
          onClick={handleLinkClick}
          tabIndex={0}
          data-gtm-click="gtm-proRegistButton-topBanner"
        >
          <div className={styles['text-wrapper']}>
            <p className={styles['content']}>
              <span className={styles['for-pro']}>事業者の方</span>
              Web集客ならゼヒトモ&nbsp;&nbsp;単月<span className={styles['profit']}>280</span>
              万円売上も!
              <ArrowInCircle />
            </p>
          </div>
        </a>
      </Link>
    </div>
  );
};

export type { Props as ProSignUpPromotionBannerProps };
export default ProSignUpPromotionBanner;
