import { FC, ReactNode } from 'react';
import LazyLoad from 'react-lazyload';

import styles from './SectionFeatures.module.scss';

interface Props {
  children: ReactNode;
  translation: {
    [key: string]: string;
    item1Icon: string;
    item1Subtitle: string;
    item1Title: string;
    item2Icon: string;
    item2Subtitle: string;
    item2Title: string;
    item3Icon: string;
    item3Subtitle: string;
    item3Title: string;
    subtitle: string;
    title: string;
  };
  isGrayFigure?: boolean;
}

const SectionFeatures: FC<Props> = ({ children = null, translation, isGrayFigure = false }) => (
  <section className={styles['figure-container']}>
    <h2 className={styles['title']} data-cy={'section-features'}>
      {translation.title}
    </h2>
    {translation.subtitle && <h3 className={styles['subtitle']}>{translation.subtitle}</h3>}
    <div className={styles['features']}>
      {/* eslint-disable-next-line no-magic-numbers */}
      {[1, 2, 3].map((id) => (
        <figure
          className={`${styles['figure']} ${isGrayFigure ? styles['gray-bordered-bg'] : ''}`}
          key={id}
          data-cy={'section-features-contents'}
        >
          {translation[`item${id}Icon`] && (
            <LazyLoad once={true}>
              <img alt={translation[`item${id}Title`]} src={translation[`item${id}Icon`]} />
            </LazyLoad>
          )}
          <div className={styles['description']}>
            {translation[`item${id}Title`] && (
              <figcaption className={styles['figcaption']}>
                {translation[`item${id}Title`]}
              </figcaption>
            )}
            {translation[`item${id}Subtitle`] && (
              <p className={styles['figure-subtitle']}>{translation[`item${id}Subtitle`]}</p>
            )}
          </div>
        </figure>
      ))}
    </div>
    {children}
  </section>
);

export default SectionFeatures;
