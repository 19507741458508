import DropdownButton from 'components/atoms/DropdownButton';
import LinksSection from 'components/molecules/LinkSection';
import { FC } from 'react';
import { c1Category, c2Category } from './types';

interface Props {
  links: c1Category;
}

export const CategoryLinks: FC<Props> = ({ links }) => {
  const content = links.c2Categories.map((category: c2Category) => (
    <LinksSection title={category.title} links={category.links} key={category.title} />
  ));

  return <DropdownButton title={links.title}>{content}</DropdownButton>;
};

export default CategoryLinks;
export type { Props as CategoryLinksProps };
