import { FC } from 'react';

import styles from './DropdownButton.module.scss';

interface Props {
  title: string;
}

export const DropdownButton: FC<Props> = ({ children, title }) => (
  <details className={styles.container} open={true} data-cy={'popular-service'}>
    <summary>
      <h4>{title}</h4>
    </summary>
    {children}
  </details>
);
export default DropdownButton;
export type { Props as DropdownButtonProps };
