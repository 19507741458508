import { connect } from 'react-redux';
import { translationSelector } from 'modules/translations/duck';

import SectionFeatures from './SectionFeatures';

const mapStateToProps = (state, ownProps) => ({
  translation: translationSelector(state, ownProps),
});

export default connect(mapStateToProps)(SectionFeatures);
